import * as React from 'react';
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import './main.scss';

const NotFound: React.FunctionComponent<{}> = () => {
    return <Layout>
        <Helmet>
            <meta charSet="utf-8"></meta>
            <title>Joey Albano 404</title>
            <link rel="canonical" href="https://joeyalbano.com/404"/>
            <meta name="description" content="Page was not found on Joey Albano's website!"/>
        </Helmet>
        <div>
            <h1>This page doesn't exist!</h1>
        </div>
    </Layout>
}

export default NotFound;

